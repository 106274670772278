<template>
  <v-row>
    <v-col xs="12" sm="12" md="8" lg="6" xl="8">
      <label>{{ label }}</label>
      <v-autocomplete
        v-if="editMode"
        v-model="model"
        no-filter="true"
        :items="entries"
        :loading="loading"
        :placeholder="$t('locationInput.placeholder')"
        item-text="description"
        dense
        outlined
        return-object
        autofocus
        hide-details="auto"
        :rules="validation ? required : []"
        @update:search-input="getPredictions"
        @change="valueChanged"
        @blur="disableEditMode"
      ></v-autocomplete>
      <v-text-field
        v-else
        :value="selectedItem()"
        outlined
        dense
        readonly
        hide-details="auto"
        :rules="validation ? required : []"
        @focus="enableEditMode"
      >
      </v-text-field>
    </v-col>
    <v-col xs="12" sm="5" md="4" lg="4" xl="2"
      v-if="!editMode && !hideRange && !selectedCountry" >
      <v-select
        v-model="range"
        :items="locationRanges"
        style="min-width: 84px;"
        :class="{ '': label && label.length > 0 }"
        item-text="description"
        item-value="id"
        :placeholder="$t('common.select')"
        dense
        outlined
        hide-details="auto"
        :rules="validation ? requiredRange : []"
        @input="emitInput"
      ></v-select>
    </v-col>
    <v-col xs="3" sm="3" md="2" lg="1" xl="1"
      v-if="clearable"
      class="d-flex align-center justify-center">
      <v-btn
        style="margin-left:23px"
        icon
        :disabled="disabledClearableBtn"
        @click="clearDataClick"
      >
        <v-icon>$trash</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import Service from '@/services'
import debounce from 'lodash.debounce'

export default {
  name: 'LocationInput',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      required: false
    },
    lg: {
      type: [String, Number],
      default: '4'
    },
    validation: {
      type: Boolean,
      default: false
    },
    hideRange: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    allowOnlyCountry: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    locationRanges () {
      return this.$store.getters.enums.locationRange
    },
    selectedCountry () {
      return this.valueDetails && this.valueDetails.country && this.valueDetails.country.length > 0 &&
        (!this.valueDetails.place || (this.valueDetails.place.city.length < 1 && this.valueDetails.place.postalCode.length < 1))
    },
    disabledClearableBtn () {
      return false
    }
  },
  data () {
    return {
      editMode: false,
      model: null,
      valueDetails: null,
      entries: [],
      range: null,
      loading: false,
      required: [() => (!!this.valueDetails && !!this.valueDetails.country && (!!this.valueDetails.place && (!!this.valueDetails.place.city || !!this.valueDetails.place.postalCode))) || this.$t('validation.fieldRequired')],
      requiredRange: [() => (!!this.valueDetails && !!this.valueDetails.country)]
    }
  },
  watch: {
    value () {
      if (this.value && this.value.place && this.value.place.range) {
        this.range = this.value.place.range
      } else {
        this.range = this.locationRanges[6].id
      }

      this.valueDetails = this.value
    },
    editMode (newValue) {
      if (!newValue) {
        this.emitInput()
      }
    }
  },
  created () {
    if (this.value && this.value.place && this.value.place.range) {
      this.range = this.value.place.range
    } else {
      this.range = this.locationRanges[6].id
    }

    this.valueDetails = this.value
  },
  methods: {
    getPredictions: debounce(async function (val) {
      if (val && val.length > 0) {
        this.loading = true
        try {
          const { data } = await Service.get(`/ExternalAPI/GetPlaceAutocomplete?allowOnlyCountry=${this.allowOnlyCountry}&search=${val}`)
          if (data.status === 'OK') {
            this.entries = data.predictions
          }
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }
    }, 500),
    async valueChanged (event) {
      try {
        this.loading = true
        const { data } = await Service.get(`/ExternalApi/GetPlaceDetails?placeId=${event.placeId}`)
        this.valueDetails = data
        this.disableEditMode()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    selectedItem () {
      let result = ''

      if (!this.valueDetails) {
        return ''
      }

      if (this.valueDetails.country && this.valueDetails.country.length > 0) {
        result += `${this.valueDetails.country},`
      }

      if (this.valueDetails.place && this.valueDetails.place.postalCode && this.valueDetails.place.postalCode.length > 0) {
        result += ` ${this.valueDetails.place.postalCode},`
      }

      if (this.valueDetails.place && this.valueDetails.place.city && this.valueDetails.place.city.length > 0) {
        result += ` ${this.valueDetails.place.city}`
      }

      if (result[result.length - 1] === ',') {
        result = result.slice(0, -1)
      }

      return result
    },
    enableEditMode () {
      this.model = null
      this.editMode = true
    },
    disableEditMode () {
      this.editMode = false
    },
    clearDataClick () {
      this.valueDetails = {
        country: null,
        place:
          {
            city: null,
            postalCode: null,
            latitude: null,
            longitude: null,
            range: null
          }
      }
      this.emitInput()
      this.$emit('removeItem', this.valueDetails)
    },
    emitInput () {
      this.$emit('input', {
        ...this.valueDetails,
        place: {
          ...this.valueDetails.place,
          range: this.range
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.location-input-range {
  margin-top: 14px
}
</style>
